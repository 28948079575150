export default [
  {
    label: '公司名稱',
    value: '公司名稱'
  },
  {
    label: '統一編號',
    value: '統一編號'
  },
  {
    label: '人名',
    value: '人名'
  },
]
