<template>
  <main class="page-home">
    <div id="printContainer" class="container">
      <div class="container-search-panel">
        <el-tabs v-model="indexTabs">
          <el-tab-pane label="單一搜尋" name="search">
            <TheCompanySearchTab>
            </TheCompanySearchTab>
          </el-tab-pane>
          <el-tab-pane label="比較人物" name="second" class="el-tab-pane--custom">
            <TheMultiPersonSearchTab>
            </TheMultiPersonSearchTab>
          </el-tab-pane>
          <el-tab-pane label="比較公司" name="third">
            <TheMultiCompanySearchTab>
            </TheMultiCompanySearchTab>
          </el-tab-pane>
          <el-tab-pane label="批次搜尋" name="batch">
            <TheBatchSearchTab>
            </TheBatchSearchTab>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 批次搜尋的表格（因為排版關係所以放在tab區域以外） -->
      <div class="container-table" v-show="indexTabs === 'batch'">
        <TheBatchSearchTable>
        </TheBatchSearchTable>
      </div>
    </div>
    <MainPageFloatButton>
    </MainPageFloatButton>
  </main>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import TheCompanySearchTab from '@/components/TheCompanySearchTab/TheCompanySearchTab.vue'
import TheMultiPersonSearchTab from '@/components/TheMultiPersonSearchTab/TheMultiPersonSearchTab.vue'
import TheMultiCompanySearchTab from '@/components/TheMultiCompanySearchTab/TheMultiCompanySearchTab.vue'
import TheBatchSearchTab from '@/components/TheBatchSearchTab/TheBatchSearchTab.vue'
import TheBatchSearchTable from '@/components/TheBatchSearchTable/TheBatchSearchTable.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'

export default {
  name: 'home',
  components: {
    TheCompanySearchTab,
    TheMultiPersonSearchTab,
    TheMultiCompanySearchTab,
    TheBatchSearchTab,
    TheBatchSearchTable,
    MainPageFloatButton
  },
  data () {
    return {
      indexTabs: 'search'
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('home', [
      'actionOptions'
    ]),
    ...mapMutations('home', [
      'mutationBatchDataList',
      'mutationSelectedBatchRows'
    ])
  },
  mounted () {
    // 將網址修改為 '/'
    window.history.replaceState(window.history.state, '', '/')

    // 清空批次上傳
    this.mutationBatchDataList([])
    this.mutationSelectedBatchRows([])
  },
  created () {
    this.actionOptions()
  }
}
</script>
