<template>
  <el-form
    label-position="top"
    :form="searchData"
    @submit.native.prevent>
    <section>
      <el-form-item class="index-search-input">
        <el-autocomplete
          v-model="searchData.keyword"
          class="index-input-with-select"
          prefix-icon="el-icon-search"
          placeholder="輸入關鍵字搜尋"
          :trigger-on-focus="false"
          :fetch-suggestions="autocomplete"
          :debounce="300"
          @select="handleSelect"
          @keyup.enter.native="search(advSearchShow ? 'advSearch' : 'search')"
        >
        </el-autocomplete>
        <el-button @click="toggleAdvSearch">進階設定</el-button>
        <el-button type="primary" @click="search('search')">
          搜尋
        </el-button>
      </el-form-item>
      <el-form-item class="index-filter-checkbox">
        <!-- <el-radio-group v-model="searchData.keyword_type">
          <el-radio
            :key="item.label"
            :label="item.value"
            v-for="item in companyKeywordTypeOptions"
          >
            {{ item.label }}
          </el-radio>
          <!- - <el-radio label="全部"></el-radio>
          <el-radio label="公司"></el-radio>
          <el-radio label="商業登記"></el-radio>
          <el-radio label="代表人"></el-radio>
          <el-radio label="統一編號"></el-radio> - ->
        </el-radio-group> -->
        <div class="title">
          公司登記狀態
        </div>
        <el-checkbox-group v-model="searchData.keyword_search_fields">
          <el-checkbox
            v-for="(item,i) in keywordSearchFieldsOptions"
            :label="item.value"
            :key="'basic'+i"
            :disabled="searchData.keyword_search_fields.length <= 1 && searchData.keyword_search_fields.includes(item.value)">
            {{item.label}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item class="index-filter-checkbox">
        <div class="title">
          搜尋類別
        </div>
        <el-checkbox-group v-model="searchData.data_type_array">
          <el-checkbox
            v-for="(item,i) in dataTypeArrayOptions"
            :label="item.value"
            :key="'basic'+i"
            :disabled="searchData.data_type_array.length <= 1 && searchData.data_type_array.includes(item.value)">
            {{item.label}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- <div class="btn-group">
        <el-button type="primary" @click="search('search')">
          搜尋
        </el-button>
        <el-button @click="toggleAdvSearch">進階設定</el-button>
        <el-dropdown style="margin-left:10px" @command="handleBatchSearchDropdown">
          <el-button type="primary">
            批次搜尋<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="上傳批次搜尋文件">
              上傳批次搜尋文件
            </el-dropdown-item>
            <el-dropdown-item>
              <a href="/files/公司範本.xlsx">
                下載公司查詢範本
              </a>
            </el-dropdown-item>
            <el-dropdown-item>
              <a href="/files/人物範本.xlsx">
                下載人物查詢範本
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
      <el-form
        ref="advSearch"
        :model="advSearchData"
        :rules="advRules"
        v-if="advSearchShow === true"
        class="advsearch clear">
        <el-row>
          <!-- 公司類型 -->
          <el-form-item :label="FILTER_ITEM_DICT.data_type">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.data_type"
            >
              <el-option
                v-for="item in dataTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 公司狀態 -->
          <el-form-item :label="FILTER_ITEM_DICT.company_state">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.company_state"
            >
              <el-option
                v-for="item in companyStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- 行業別（母選單） -->
          <el-form-item :label="`${FILTER_ITEM_DICT.etax_matters} (類別)`">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="etaxMattersCodes"
            >
              <el-option
                v-for="item in etaxMattersOptionsMaster"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 行業別（子選單） -->
          <el-form-item
            prop="etax_matters"
            :label="FILTER_ITEM_DICT.etax_matters">
            <el-select
              placeholder="請選擇"
              multiple
              :disabled="etaxMattersOptionsDetail.length == 0"
              v-model="advSearchData.etax_matters"
            >
              <el-option
                v-for="item in etaxMattersOptionsDetail"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- 營業狀態 -->
          <el-form-item :label="FILTER_ITEM_DICT.company_operate">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.company_operate"
            >
              <el-option
                v-for="item in companyOperateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 員工人數 -->
          <el-form-item :label="FILTER_ITEM_DICT.employee_count">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.employee_count"
            >
              <el-option
                v-for="item in employeeCountOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- 所在地區 -->
          <el-form-item :label="FILTER_ITEM_DICT.company_county">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.company_county"
            >
              <el-option
                v-for="item in companyCountyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="　">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.companyCountySub"
            >
              <el-option
                v-for="item in currentCompanyCountySubOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- 成立年限 -->
          <el-form-item :label="FILTER_ITEM_DICT.date_approved">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.date_approved"
            >
              <el-option
                v-for="item in dateApprovedOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 資本總額 -->
          <el-form-item :label="FILTER_ITEM_DICT.capital_amount">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.capital_amount"
            >
              <el-option
                v-for="item in capitalAmountOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- 員工人數 -->
          <!-- <el-form-item label="員工人數">
            <el-select v-model="advSearchData.companyType" placeholder="請選擇">
              <el-option
                v-for="item in companyTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- 股權狀況 -->
          <el-form-item :label="FILTER_ITEM_DICT.stock_state">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.stock_state"
            >
              <el-option
                v-for="item in stockStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 公開發行 -->
          <!-- <el-form-item label="公開發行">
            <el-select v-model="advSearchData.companyType" placeholder="請選擇">
              <el-option
                v-for="item in companyTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- 最近一年度總進口實績 -->
          <el-form-item :label="FILTER_ITEM_DICT.imports">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.imports"
            >
              <el-option
                v-for="item in importsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <!-- 最近一年度總出口實績 -->
          <el-form-item :label="FILTER_ITEM_DICT.exports">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.exports"
            >
              <el-option
                v-for="item in exportsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 最近一個月得標金額 -->
          <el-form-item :label="FILTER_ITEM_DICT.procurement_history">
            <el-select
              placeholder="請選擇"
              multiple
              v-model="advSearchData.procurement_history"
            >
              <el-option
                v-for="item in procurementHistoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <div class="btn-group btn-group--fill">
          <el-button type="primary" @click="search('advSearch')"
            >進階搜尋</el-button
          >
          <el-button type="text" @click="resetAdvSearch"
            >清空進階搜尋</el-button
          >
        </div>
      </el-form>
    </section>
    <!-- 隱藏上傳按鈕 -->
    <input type="file" id="fileUploader" name="fileUploader" accept=".csv,.xls,.xlsx" v-show="false"/>
  </el-form>
</template>

<script>
import XLSX from 'xlsx'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Message } from 'element-ui'
import routerService from '@/utils/routerService.js'
import * as codes from '@/const/codes.ts'
import * as apis from '@/apis/index.ts'
// import companyKeywordTypeOptions from '@/const/companyKeywordTypeOptions.js'
import keywordSearchFieldsOptions from '@/const/keywordSearchFieldsOptions.js'
import dataTypeArrayOptions from '@/const/dataTypeArrayOptions.js'
import etaxMattersOptionsMaster from '@/const/etaxMattersOptionsMaster.js'
// import companyTypeOptions from '@/const/companyTypeOptions.js'
// import companyStatusOptions from '@/const/companyStatusOptions.js'
// import capitalAmountOptions from '@/const/capitalAmountOptions.js'
// import cityOptions from '@/const/cityOptions.js'

// const defaultKeywordType

const defaultAdvSearchData = {
  data_type: [],
  company_state: [],
  etax_matters: [],
  company_operate: [],
  company_county: [],
  companyCountySub: [],
  date_approved: [],
  capital_amount: [],
  employee_count: [],
  stock_state: [],
  imports: [],
  exports: [],
  procurement_history: []
}

function parseFileDataToKeyword (data) {
  const columnNames = ['公司名稱', '統一編號', '人物姓名']
  let keywordArr = []
  data.forEach(row => {
    Object.keys(row).forEach(name => {
      if (columnNames.includes(name) && row[name]) {
        keywordArr.push(row[name])
      }
    })
  })
  return keywordArr.join('|')
}

export default {
  data () {
    return {
      FILTER_ITEM_DICT: codes.FILTER_ITEM_DICT,
      // companyKeywordTypeOptions,
      keywordSearchFieldsOptions,
      dataTypeArrayOptions,
      // companyTypeOptions,
      // companyStatusOptions,
      // capitalAmountOptions,
      // cityOptions,
      advSearchShow: false,
      // 查詢basic欄位
      searchData: {
        keyword: '',
        // keyword_type: 'all'
        "keyword_search_fields": ["公司名稱", "統一編號", "人名"],
        "data_type_array": ["公司", "商業登記"]
      },
      advSearchData: JSON.parse(JSON.stringify(defaultAdvSearchData)),
      advRules: {
        etax_matters: [{ validator: this.validateEtaxMatters }]
      },
      formComparePeople: {
        comparePeopleInput: '',
        comparePeopleSelect: '',
        comparePeopleItem: [],
        comparePeopleItenStartDay: '',
        comparePeopleItenEndDay: ''
      },
      etaxMattersCodes: [] // 行業別主選單代碼
    }
  },
  computed: {
    ...mapState('home', [
      'dataTypeOptions',
      'companyStateOptions',
      'etaxMattersOptions',
      'companyOperateOptions',
      'companyCountyOptions',
      'companyCountySubOptions',
      'dateApprovedOptions',
      'capitalAmountOptions',
      'employeeCountOptions',
      'stockStateOptions',
      'importsOptions',
      'exportsOptions',
      'procurementHistoryOptions'
      // 'companyOperateOptions',
    ]),
    ...mapGetters([
      'getterUserEmail'
    ]),
    currentCompanyCountySubOptions () {
      let options = []
      this.advSearchData.company_county.forEach(d => {
        const companyCounty = JSON.parse(d)
        if (this.companyCountySubOptions[companyCounty.key]) {
          options = options.concat(this.companyCountySubOptions[companyCounty.key])
        }
      })
      return options
    },
    etaxMattersOptionsMaster () {
      const options = etaxMattersOptionsMaster.map(optionsMaster => {
        const masterValues = optionsMaster.value.split(',')
        // 尋找此母選單是否有值
        const hasValue = this.etaxMattersOptions.some(data => {
          const hasValue = masterValues.includes(data._etaxCode)
          return hasValue
        })
        optionsMaster.disabled = hasValue ? false : true
        return optionsMaster
      })
      return options
    },
    etaxMattersOptionsDetail () {
      // 取得母選單代碼清單
      let etaxMattersCodes = []
      this.etaxMattersCodes.forEach(d => {
        etaxMattersCodes = etaxMattersCodes.concat(d.split(','))
      })
      // 代碼篩選
      const options = this.etaxMattersOptions.filter(d => {
        return etaxMattersCodes.includes(d._etaxCode)
      })
      return options
    },
    keywordSearchFields () {
      const ValueSet = new Set(this.searchData.keyword_search_fields)
      if (ValueSet.has('公司名稱')) {
        ValueSet.add('統一編號') // 有勾選到"公司名稱"則也要有"統一編號"
      }
      return Array.from(ValueSet)
    }
  },
  methods: {
    ...mapMutations('home', ['mutationSearchQuery']),
    validateEtaxMatters (rule, value, callback) {
      if (this.etaxMattersCodes.length == 0 || value.length > 0) { // value: advSearch.etax_matters
        callback()
      } else {
        callback('請完成選擇行業別')
      }
    },
    autocomplete (queryString, cb) {
      // @Q@ 星展特規
      queryString = queryString.replace('-台灣分公司', '').replace('台灣分公司', '').replace('-台灣辦事處', '').replace('台灣辦事處', '')

      // if (this.searchData.keyword_search_fields.includes('人名')) {
      //   let searchRequest = {
      //     basic: {
      //       get: ['list'],
      //       keyword: queryString,
      //       field: 'representative_name', // @Q@ 因後端命名方式有所差異所以寫死，同owner資料
      //       fetch_less_fields: 1,
      //       'page-size': 10,
      //       'page-index': 1,
      //       'search-label': {
      //         user: this.getterUserEmail,
      //         label: this.searchData.keyword
      //       }
      //     }
      //   }
      //   apis.namesGet(searchRequest).then(data => {
      //     if (data && data.payload && data.payload.list) {
      //       let list = data.payload.list.slice(0, 10)
      //       list = list.map(d => {
      //         return {
      //           label: d,
      //           value: d
      //         }
      //       })
      //       // -- 回傳值 --
      //       cb(list)
      //     }
      //   })
      // } else {
        let searchRequest = {
          basic: {
            get: ['list'],
            keyword: queryString,
            // keyword_type: this.searchData.keyword_type,
            keyword_search_fields: this.keywordSearchFields,
            data_type_array: this.searchData.data_type_array,
            fetch_less_fields: 1,
            'page-size': 10,
            'page-index': 1,
            'search-label': {
              user: this.getterUserEmail,
            }
          }
        }
        // if (this.searchData.keyword_type === 'all'
        // || this.searchData.keyword_type === 'company'
        // || this.searchData.keyword_type === 'registraction') {
          searchRequest.basic['search-label'].label = this.searchData.keyword
        // }
        apis.companyListGet(searchRequest).then(data => {
          if (data && data.payload && data.payload.list) {
            let list = []
            // 過濾重覆的資料
            list = data.payload.list.reduce((prev, data) => {
              if (prev.find(d => d.value === data.value) == null) {
                prev.push(data)
              }
              return prev
            }, [])
            // -- 顯示格式所需資料 --
            // if (this.searchData.keyword_search_fields.includes('統一編號')) {
            //   list = data.payload.list.map(d => {
            //     d.value = d.uniID
            //     return d
            //   })
            // } else {
              list = data.payload.list.map(d => {
                d.value = d.company_name
                return d
              })
            // }
            // -- 回傳值 --
            cb(list)
          }
        })
      // }
    },
    handleSelect () {},
    toggleAdvSearch () {
      this.advSearchShow = !this.advSearchShow
    },
    async search (type) {
      if (type === 'advSearch') {
        const validateResult = await this.$refs.advSearch.validate()
        if (!validateResult) {
          return
        }
      }

      // 查詢條件
      let query = {
        basic: {
          get: ['list', 'cls'],
          data_type_array: this.searchData.data_type_array,
          'page-index': 1,
          sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE),
          'search-label': {
            // label: '',
            user: this.getterUserEmail
          }
        },
      }
      if (this.searchData.keyword) {
        query.basic.keyword = this.searchData.keyword
        // @Q@ 星展特規
        query.basic.keyword = query.basic.keyword.replace('-台灣分公司', '').replace('台灣分公司', '').replace('-台灣辦事處', '').replace('台灣辦事處', '')
      } else {
        query.basic.ignore_keyword = 1
      }
      query.basic.keyword_search_fields = this.keywordSearchFields
      // if (this.searchData.keyword_type === 'all'
      // || this.searchData.keyword_type === 'company'
      // || this.searchData.keyword_type === 'registraction'
      // || this.searchData.keyword_type === 'owner') {
        query.basic['search-label'].label = this.searchData.keyword
      // }
      // 記錄原始勾選狀態
      query.basic._keyword_search_fields = this.searchData.keyword_search_fields
      // -- 點選「進階查詢」要包含進階查詢條件 --
      if (type === 'advSearch') {
        query.filter = []

        Object.keys(this.advSearchData).forEach(key => {
          const values = this.advSearchData[key].map(d => JSON.parse(d))
          if (values.length) {
            let filterQuery = {
              method: 'should',
              query: [],
              _desc: '' // 紀錄選單名稱（僅前端使用）
            }
            // if (key === 'data_type') {
            //   // 只有選擇一筆時才取值，因為不選或兩個都選視同不加入篩選條件（查詢全部）
            //   query.basic.data_type = values[0].key
            // } else {
            const queries = values.map(d => d.queries)
            filterQuery.query = filterQuery.query.concat(queries)
            // }
            let text = ''
            if (key === 'imports' || key === 'exports') {
              // 轉換進出口代碼
              text = values
                .map(d => {
                  return codes.IMPORT_AND_EXPORT_DATA[d.key].label
                })
                .join(',')
            } else {
              text = values.map(d => d.key).join(',')
            }
            filterQuery._desc = `${codes.FILTER_ITEM_DICT[key]}(${text})`
            query.filter.push(filterQuery)
          }
        })
      }
      // dataType
      // if (query.basic.data_type) {
      //   dataTypeIndex = codes.DATA_TYPE_INDEX_DIST[query.basic.data_type]
      // }
      // query
      const queryString = JSON.stringify(query)
      // this.$router.push({
      routerService('push', {
        name: 'companyList',
        params: {
          dataTypeIndex: this.searchData.data_type_array.length === 2
            ? 'default'
              : this.searchData.data_type_array[0] === '公司'
                ? 'company'
                : 'business'
        },
        query: {
          originQuery: queryString,
          currentQuery: queryString
        }
      })
    },
    resetAdvSearch () {
      this.advSearchData = JSON.parse(JSON.stringify(defaultAdvSearchData))
    },
    // handleBatchSearchDropdown (d) {
    //   if (d === '上傳批次搜尋文件') {
    //     window.document.querySelector('#fileUploader').click()
    //   }
    // }
  },
  mounted () {
    // window.document.querySelector('#fileUploader')
    //   .addEventListener('change', (evt) => {
    //     let selectedFile = evt.target.files[0]
    //     let reader = new FileReader()
    //     reader.onload = (event) => {
    //       let data = event.target.result
    //       let workbook = XLSX.read(data, {
    //         type: 'binary'
    //       })
    //       workbook.SheetNames.forEach((sheetName) => {
    //         let XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
    //         if (XL_row_object.length > 0) {
    //           // document.getElementById("jsonObject").innerHTML = JSON.stringify(XL_row_object);
    //           // console.log(JSON.stringify(XL_row_object))
    //           this.searchData.keyword = parseFileDataToKeyword(XL_row_object)
    //         }
    //       })
    //     }
    //     reader.onerror = (event) => {
    //       console.error("File could not be read! Code " + event.target.error.code);
    //     }
    //     // 讀取上傳文件為二進制
    //     reader.readAsBinaryString(selectedFile);

    //     // 清除掉value否則第二次點擊會無法觸發'change'事件
    //     evt.target.value = ''
    //   })
  },
  watch: {
    'etaxMattersCodes.length': {
      handler (length) {
        this.$refs.advSearch.validate()
      }
    }
  }
}
</script>

