








































































































import XLSX from 'xlsx'
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import type { Ref, ComputedRef, SetupContext } from '@vue/composition-api'
// import { mapState, mapGetters, mapMutations } from 'vuex'
// import { Message } from 'element-ui'
// import * as codes from '@/const/codes'
// import * as apis from '@/apis/index'
// import * as codes from '@/const/codes'
import type { XlsxRow, XlsxRowsSimplified } from '@/types/apis'
import routerService from '@/utils/routerService.js'

interface Props {
}

// interface AutocompleteItem {
//   label: string
//   value: string
// }

// interface XlsxRow {
//   附註: string
//   公司名稱: string
//   公司統編: string
// }

// interface PersonXlsxRow {
//   人物姓名: string
// }


// function parseFileDataToKeyword (data: {[key: string]: string}[]) {
//   const columnNames = ['公司名稱', '統一編號', '人物姓名']
//   let keywordArr: string[] = []
//   data.forEach(row => {
//     Object.keys(row).forEach(name => {
//       if (columnNames.includes(name) && row[name]) {
//         keywordArr.push(row[name])
//       }
//     })
//   })
//   return keywordArr.join('|')
// }

function parseCompanyFileDataRows (data: {[key: string]: string}[]): XlsxRow[] {
  return data.map(row => {
    return {
      附註: row['附註'],
      公司名稱: row['公司名稱'],
      公司統編: row['統一編號']
        ? String(row['統一編號']).padStart(8, '0')
        : '' // 因為excel欄位很容易因為使用者操作沒正確使用字串格式而漏掉0，所以這邊要補滿0到8位數字
    }
  })
}

function parsePeopleFileKeywords (data: {[key: string]: string}[]): string[] {
  return data.map(d => d.人物姓名).filter(d => d)
}

export default defineComponent({
  props: {
    options: {
      type: Array
    },
    value: {
      type: String
    }
  },
  setup (props: Props, { root, emit }: SetupContext) {

    const getterUserEmail = computed(() => {
      // return mapGetters(['getterUserEmail'])
      return root.$store.getters['getterUserEmail']
    })

    // const inputSelect = ref('')

    const batchDataType = ref<'公司' | '人物'>('公司')

    const fileName = ref('')

    const searchData = ref({
      keyword: '',
      keyword_type: 'all'
    })

    const peopleKeywords = ref<string[]>([])

    const tableAmount = computed(() => {
      return root.$store.state.home.batchDataList.length
    })

    const selectedAmount = computed(() => {
      return (root.$store.state.home.selectedBatchRows as XlsxRowsSimplified[]).reduce((prev, row) => {
        return prev + row.查詢結果筆數
      }, 0)
    })

    const maxSelectedAmount = ref(2000)

    // 選擇筆數是否超過上限
    const isSelectedAmountExceed = computed(() => {
      if (batchDataType.value === '公司') {
        return selectedAmount.value > maxSelectedAmount.value
      } else {
        return peopleKeywords.value.length > maxSelectedAmount.value
      }
    })

    const isSearchingDisabled = computed(() => {
      if (batchDataType.value === '公司') {
        return selectedAmount.value == 0 || isSelectedAmountExceed.value
      } else {
        return peopleKeywords.value.length == 0 || isSelectedAmountExceed.value
      }
    })

    const autocomplete = (queryString: string, cb: Function) => {
      
      cb([])
      return
      // @Q@ 取消掉autocomplete
      // // @Q@ 星展特規
      // queryString = queryString.replace('-台灣分公司', '').replace('台灣分公司', '').replace('-台灣辦事處', '').replace('台灣辦事處', '')
      // if (searchData.value.keyword_type === 'owner') {
      //   let searchRequest = {
      //     basic: {
      //       get: ['list'],
      //       keyword: queryString,
      //       field: 'representative_name', // @Q@ 因後端命名方式有所差異所以寫死，同owner資料
      //       fetch_less_fields: 1,
      //       'page-size': 10,
      //       'page-index': 1,
      //       'search-label': {
      //         user: getterUserEmail.value,
      //         label: searchData.value.keyword
      //       }
      //     }
      //   }
      //   apis.namesGet(searchRequest).then(data => {
      //     if (data && data.payload && data.payload.list) {
      //       let list: AutocompleteItem[] = data.payload.list
      //         .slice(0, 10)
      //         .map(d => {
      //           return {
      //           label: d,
      //           value: d
      //         }
      //       })
      //       // -- 回傳值 --
      //       cb(list)
      //     }
      //   })
      // } else {
      //   let searchRequest = {
      //     basic: {
      //       get: ['list'],
      //       keyword: queryString,
      //       keyword_type: searchData.value.keyword_type,
      //       fetch_less_fields: 1,
      //       'page-size': 10,
      //       'page-index': 1,
      //       'search-label': {
      //         user: getterUserEmail.value,
      //         label: searchData.value.keyword_type === 'all'
      //           || searchData.value.keyword_type === 'company'
      //           || searchData.value.keyword_type === 'registraction'
      //             ? searchData.value.keyword
      //             : undefined
      //       }
      //     }
      //   }
      //   apis.companyListGet(searchRequest).then(data => {
      //     if (data && data.payload && data.payload.list) {
      //       let list = []
      //       // 過濾重覆的資料
      //       list = data.payload.list.reduce((prev, data) => {
      //         if (prev.find(d => d.value === data.value) == null) {
      //           prev.push(data)
      //         }
      //         return prev
      //       }, [])
      //       // -- 顯示格式所需資料 --
      //       if (
      //         searchData.value.keyword_type === 'all' ||
      //         searchData.value.keyword_type === 'company' ||
      //         searchData.value.keyword_type === 'registraction'
      //       ) {
      //         list = data.payload.list.map(d => {
      //           d.value = d.company_name
      //           return d
      //         })
      //       } else if (searchData.value.keyword_type === 'tax_number') {
      //         list = data.payload.list.map(d => {
      //           d.value = d.uniID
      //           return d
      //         })
      //       }
      //       // -- 回傳值 --
      //       cb(list)
      //     }
      //   })
      // }
    }

    const handleBatchSearchDropdown = (d: string) => {
      if (d === '上傳批次搜尋文件') {
        const fileUploader = window.document.querySelector('#fileUploader') as HTMLElement
        if (fileUploader) {
          fileUploader.click()
        }
      }
    }

    const handleSelect = (item: any) => {
      
    }

    const search = () => {
      // 查詢條件
      const query = batchDataType.value === '公司'
        ? {
          basic: {
            get: ['list', 'cls'],
            'page-index': 1,
            data_type_array: ['公司', '商業登記'],
            // sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE),
            'search-label': {
              label: fileName.value,
              user: getterUserEmail.value
            },
            'xlsx-rows': root.$store.state.home.selectedBatchRows
          },
        }
        : {
          basic: {
            get: ['list', 'cls'],
            'page-index': 1,
            // sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE),
            'search-label': {
              label: fileName.value,
              user: getterUserEmail.value
            },
            keyword: peopleKeywords.value.join('|')
          }
        }
      
      // dataType
      // if (query.basic.data_type) {
      //   dataTypeIndex = codes.DATA_TYPE_INDEX_DIST[query.basic.data_type]
      // }
      // query
      const queryString = JSON.stringify(query)
      // this.$router.push({
      routerService('push', {
        name: 'companyList',
        params: {
          dataTypeIndex: 'default' // 初始值為預設，進入查詢頁再依查詢結果塞值
        },
        query: {
          originQuery: queryString,
          currentQuery: queryString
        }
      })
    }

    onMounted(() => {
      const filUploader = window.document.querySelector('#fileUploader') as HTMLElement
      if (filUploader) {
        filUploader.addEventListener('change', (evt) => {
          let selectedFile: File = (evt.target as HTMLInputElement) && (evt.target as HTMLInputElement).files![0]
          // console.log('selectedFile', selectedFile, selectedFile.name, evt)
          fileName.value = selectedFile.name

          let reader = new FileReader()
          reader.onload = (event) => {
            // console.log('event', event)
            let data = event.target!.result
            let workbook = XLSX.read(data, {
              type: 'binary'
            })
            // console.log('workbook', workbook)
            workbook.SheetNames.forEach((sheetName) => {
              let XL_row_object: {[key: string]: string}[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
              if (XL_row_object.length > 0) {
                // document.getElementById("jsonObject").innerHTML = JSON.stringify(XL_row_object);
                // console.log(JSON.stringify(XL_row_object))

                // console.log('XL_row_object', XL_row_object)
                if (XL_row_object[0]['人物姓名']) {
                  peopleKeywords.value = parsePeopleFileKeywords(XL_row_object)

                  batchDataType.value = '人物'
                  
                } else {
                  const xlsxRows = parseCompanyFileDataRows(XL_row_object)

                  root.$store.commit('home/mutationBatchDataType', '公司')

                  batchDataType.value = '公司'

                  // apis.companyListGet({
                  //   basic: {
                  //     "get": [
                  //         "xlsx-rows-simplified" // 表示取得 xlsx 精簡過的結果, 
                  //         // 取得的每個結果只會有公司名稱與統編, 
                  //         // 並且每行最多顯示 3 筆找到的公司(參考spec)
                  //     ],
                  //     "xlsx-rows": xlsxRows
                  //   }

                  // }).then(data => {
                  //   data.payload['xlsx-rows-simplified'][0].查詢結果筆數 = 0 // @Q@ test
                  //   root.$store.commit('home/mutationXlsxRowsSimplified', data.payload['xlsx-rows-simplified'])
                  // })
                  root.$store.dispatch('home/actionBatchSearch', xlsxRows)

                  // searchData.value.keyword = parseFileDataToKeyword(XL_row_object)
                }
              }
            })
          }
          reader.onerror = (event) => {
            console.error("File could not be read! Code " + event.target!.error!.code);
          }
          // 讀取上傳文件為二進制
          reader.readAsBinaryString(selectedFile);

          // 清除掉value否則第二次點擊會無法觸發'change'事件
          (evt.target! as any).value = '' // @Q@ 要測試確認一下是否有value這個欄位
        })
      }
    })

    return {
      // inputSelect,
      batchDataType,
      fileName,
      searchData,
      tableAmount,
      selectedAmount,
      maxSelectedAmount,
      isSelectedAmountExceed,
      isSearchingDisabled,
      autocomplete,
      handleBatchSearchDropdown,
      handleSelect,
      search
    }
  }
})
