










































































import { defineComponent, ref, computed, onMounted, watch } from '@vue/composition-api'
import type { SetupContext, WritableComputedRef } from '@vue/composition-api'
import type { XlsxRowsSimplified } from '@/types/apis'
// import type { BatchTableData } from '@/types/home'

interface Props {
  options: any
}

export default defineComponent({
  props: {
  },
  setup (props: Props, { root, emit }: SetupContext) {

    const tableRef = ref()

    const multipleSelection = ref([])

    // const tableData = ref([]
    // )

    // const batchDataList = computed({
    //   set (val: any) {
    //     root.$store.commit('home/mutationXlsxRowsSimplified', val)
    //   },
    //   get () {
    //     return tableData.value
    //   }0
    // })

    const batchDataList = computed<XlsxRowsSimplified[]>(() => root.$store.state.home.batchDataList)

    const syncBatchDataList = ref<XlsxRowsSimplified[]>([])
    watch(batchDataList, (rows) => {
      syncBatchDataList.value = rows
      
      // tableRef.value.toggleRowSelection(rows)
      if (rows) {
        // console.log('rows', rows)
        // console.log('tableRef.value', tableRef.value)
        setTimeout(() => {
          tableRef.value.toggleAllSelection() // 先全部勾選起來，後面再各別取消勾選，比較不會有效能問題
          rows
            .filter(row => row.查詢結果筆數 <= 0)
            .forEach(row => {
              tableRef.value.toggleRowSelection(row, false);
            })
          // setTimeout(() => {
          //   console.log(tableRef.value)
          // })
        })
        
      } else {
        tableRef.value.clearSelection();
      }
    })


    const handleSelectionChange = (rows: any[]) => {
      
      // multipleSelection.value = rows
      // console.log('handleSelectionChange', rows)
      root.$store.commit('home/mutationSelectedBatchRows', rows)
    }

    const handleSortChange = (val: any) => {
      console.log(val)
    }

    const checkSelectable = (row: any, index: number) => {
      return row.查詢結果筆數 > 0
    }

    // const amountSortMethod = (a: number, b: number) => {
    //   console.log(a, b)
    //   return a.查詢結果筆數 - b.查詢結果筆數
    // }
    
    return {
      tableRef,
      // tableData,
      syncBatchDataList,
      checkSelectable,
      handleSelectionChange,
      handleSortChange,
      // amountSortMethod
    }
  }
})
