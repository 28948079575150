export default [
  {
    label: '公司',
    value: '公司'
  },
  {
    label: '商業登記',
    value: '商業登記'
  }
]
